import { useCallback, useEffect, useState } from "react";
import { showAsteriskIfEmpty } from "../../utils/helpers/asterisk";
import { DebouncedState } from "usehooks-ts";

interface SponsorDetailsProps {
  sponsorName: string;
  setSponsorName: (newSponsorName: string) => void;
  sponsorAsicId: string;
  setSponsorAsicId: (newSponsorAsicId: string) => void;
  sponsorAsicExpiryMonth: string;
  setSponsorAsicExpiryMonth: (newSponsorAsicExpiryMonth: string) => void;
  sponsorAsicExpiryYear: string;
  setSponsorAsicExpiryYear: (newSponsorAsicExpiryYear: string) => void;
  sponsorCompany: string;
  setSponsorCompany: (newSponsorCompany: string) => void;
  sponsorEmail: string;
  setSponsorEmail: (newSponsorEmail: string) => void;
  sponsorMobileNumber: string;
  setSponsorMobileNumber: (newSponsorMobileNumber: string) => void;
  setInvalidForm: (newInvalidForm: boolean) => void;
  getSponsorDetails: DebouncedState<(asicId: string) => Promise<void>>;
  loadingSponsor: boolean;
  setLoadingSponsor: (loading: boolean) => void;
}

export const SponsorDetails = (props: SponsorDetailsProps) => {
  const [sponsorAsicExpired, setSponsorAsicExpired] = useState<boolean>(false);

  useEffect(() => {
    props.setInvalidForm(
      !props.sponsorName ||
        !props.sponsorCompany ||
        (!props.sponsorEmail && !props.sponsorMobileNumber)
    );
  }, [
    props.sponsorName,
    props.sponsorCompany,
    props.sponsorEmail,
    props.sponsorMobileNumber,
  ]);

  const updateSponsorAsic = useCallback((e) => {
    props.setLoadingSponsor(true);
    props.setSponsorAsicId(e.target.value.toUpperCase());
    props.getSponsorDetails(e.target.value.toUpperCase());
  }, []);

  return (
    <div className="mt-4 max-w-screen-sm">
      <div className="grid grid-cols-6 gap-6">
        <div className="col-span-6 sm:col-span-3">
          <div className="rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-customOrange px-3 pb-1.5 pt-2.5">
            <label
              htmlFor="sponsor-asic-id"
              className="block text-xs font-medium text-gray-900"
            >
              Sponsor ASIC ID{showAsteriskIfEmpty(props.sponsorAsicId)}
            </label>
            <input
              id="sponsor-asic-id"
              name="sponsor-name"
              type="text"
              autoComplete="new-sponsor-asic-id"
              required
              value={props.sponsorAsicId}
              onChange={updateSponsorAsic}
              className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-transparent"
            />
          </div>
          {props.loadingSponsor && (
            <p className="text-xs font-medium text-gray-900 pt-1">
              Fetching sponsor details...
            </p>
          )}
        </div>

        <div className="col-span-6 sm:col-span-3">
          <div className="bg-slate-200 rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-customOrange">
            <label
              htmlFor="sponsor-name"
              className="block text-xs font-medium text-gray-900"
            >
              Sponsor Name{showAsteriskIfEmpty(props.sponsorName)}
            </label>
            <input
              disabled
              id="sponsor-name"
              name="sponsor-name"
              type="text"
              autoComplete="new-sponsor-name"
              required
              value={props.sponsorName}
              onChange={(e) => props.setSponsorName(e.target.value)}
              className=" block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-transparent"
            />
          </div>
        </div>

        <div className="col-span-6 sm:col-span-3">
          <div className="bg-slate-200 rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-customOrange">
            <label
              htmlFor="sponsor-company"
              className="block text-xs font-medium text-gray-900"
            >
              Sponsor Company{showAsteriskIfEmpty(props.sponsorCompany)}
            </label>
            <input
              disabled
              id="sponsor-company"
              name="sponsor-company"
              type="text"
              autoComplete="new-sponsor-company"
              required
              value={props.sponsorCompany}
              onChange={(e) => props.setSponsorCompany(e.target.value)}
              className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-transparent"
            />
          </div>
        </div>

        <div className="col-span-6 sm:col-span-3">
          <div className="bg-slate-200 rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-customOrange">
            <label
              htmlFor="sponsor-email"
              className="block text-xs font-medium text-gray-900"
            >
              Sponsor Email
              {showAsteriskIfEmpty(
                props.sponsorMobileNumber || props.sponsorEmail
                  ? "non-empty"
                  : ""
              )}
            </label>
            <input
              disabled
              id="sponsor-email"
              name="sponsor-email"
              type="text"
              autoComplete="new-sponsor-email"
              required
              value={props.sponsorEmail}
              onChange={(e) => props.setSponsorEmail(e.target.value)}
              className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-transparent"
            />
          </div>
        </div>

        <div className="col-span-6 sm:col-span-3">
          <div className="bg-slate-200 rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-customOrange">
            <label
              htmlFor="sponsor-mobile"
              className="block text-xs font-medium text-gray-900"
            >
              Sponsor Mobile Number
              {showAsteriskIfEmpty(
                props.sponsorMobileNumber || props.sponsorEmail
                  ? "non-empty"
                  : ""
              )}
            </label>
            <input
              disabled
              id="sponsor-mobile"
              name="sponsor-mobile"
              type="text"
              autoComplete="new-sponsor-mobile"
              required
              value={props.sponsorMobileNumber}
              onChange={(e) => props.setSponsorMobileNumber(e.target.value)}
              className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-transparent"
            />
          </div>
        </div>

        <div className="col-span-6 sm:col-span-3">
          <div className="bg-slate-200 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-customOrange px-3 pb-1.5 pt-2.5">
            <label
              htmlFor="sponsor-asic-expiry-month"
              className="block text-xs font-medium text-gray-900"
            >
              Sponsor ASIC Expiry Month
              {showAsteriskIfEmpty(props.sponsorAsicExpiryMonth)}
            </label>
            <select
              disabled
              id="sponsor-asic-expiry-month"
              name="sponsor-asic-expiry-month"
              autoComplete="new-sponsor-asic-expiry-month"
              value={props.sponsorAsicExpiryMonth || ""}
              onChange={(e) => props.setSponsorAsicExpiryMonth(e.target.value)}
              required
              className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-transparent"
            >
              <option value="1">January</option>
              <option value="2">February</option>
              <option value="3">March</option>
              <option value="4">April</option>
              <option value="5">May</option>
              <option value="6">June</option>
              <option value="7">July</option>
              <option value="8">August</option>
              <option value="9">September</option>
              <option value="10">October</option>
              <option value="11">November</option>
              <option value="12">December</option>
            </select>
          </div>
        </div>

        <div className="col-span-6 sm:col-span-3">
          <div className="bg-slate-200 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-customOrange px-3 pb-1.5 pt-2.5">
            <label
              htmlFor="sponsor-asic-expiry-year"
              className="block text-xs font-medium text-gray-900"
            >
              Sponsor ASIC Expiry Year
              {showAsteriskIfEmpty(props.sponsorAsicExpiryYear)}
            </label>
            <select
              disabled
              id="sponsor-asic-expiry-year"
              name="sponsor-asic-expiry-year"
              autoComplete="new-sponsor-asic-expiry-year"
              value={props.sponsorAsicExpiryYear}
              onChange={(e) => props.setSponsorAsicExpiryYear(e.target.value)}
              required
              className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-transparent"
            >
              <option value="">Select Year</option>
              <option value={new Date().getFullYear()}>
                {new Date().getFullYear()}
              </option>
              <option value={new Date().getFullYear() + 1}>
                {new Date().getFullYear() + 1}
              </option>
              <option value={new Date().getFullYear() + 2}>
                {new Date().getFullYear() + 2}
              </option>
            </select>
          </div>
        </div>

        {sponsorAsicExpired && (
          <div className="col-span-6 flex flex-row text-sm font-medium text-red-500 mt-3">
            <div className="my-1">
              Please choose an ASIC expiry month and year that is not currently
              expired.
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
