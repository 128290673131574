import { CameraIcon, RefreshIcon } from "@heroicons/react/outline";
import { useCallback, useEffect, useRef, useState } from "react";
import Webcam from "react-webcam";
import { showAsteriskIfEmpty } from "../../utils/helpers/asterisk";
import { idOverlay } from "../../components/Icons/Overlays";
import { isUsingMobileDevice } from "../../utils/helpers/misc";

interface IDPhotoProps {
  idPhotoFront: string;
  setIdPhotoFront: (newIdPhotoFront: string) => void;
  setInvalidForm: (newInvalidForm: boolean) => void;
  idName?: string;
}

export const IDPhoto = (props: IDPhotoProps) => {
  const [error, setError] = useState<string>("");
  const [webcamError, setWebcamError] = useState<string>("");
  const [cameraLoaded, setCameraLoaded] = useState(false);
  const [facingMode, setFacingMode] = useState<string>("environment");
  const webcamRef = useRef<Webcam>(null);

  useEffect(() => {
    props.setInvalidForm(!props.idPhotoFront);
  }, [props.idPhotoFront, props.setInvalidForm]);

  const handleUserMediaError = useCallback((error: string | DOMException) => {
    console.error("Webcam error:", error);
    let errorMessage =
      "Unable to access the webcam. Please ensure that you have a webcam connected and that you have granted access to it.";
    if (error instanceof DOMException) {
      errorMessage = `${errorMessage} Error name: ${error.name}, Message: ${error.message}`;
    }
    setWebcamError(errorMessage);
  }, []);

  const capturePhoto = useCallback(() => {
    try {
      if (webcamRef.current) {
        const imageSrc = webcamRef.current.getScreenshot();
        if (imageSrc) {
          props.setIdPhotoFront(imageSrc);
        } else {
          setError("Loading webcam... please wait.");
        }
      } else {
        setError("Loading webcam... please wait.");
      }
    } catch (error) {
      setError(
        "An unknown error occurred. Please try again or contact support."
      );
    }

    setTimeout(() => setError(""), 2000);
  }, [webcamRef, props.setIdPhotoFront]);

  const handleUserMedia = () => setCameraLoaded(true);

  return (
    <div className="mt-4 max-w-screen-sm">
      <div>
        <label className="inline-block text-center sm:text-left w-full mb-2 text-gray-500">
          Take a photo of the front of your {props.idName ?? "ID"}
          {showAsteriskIfEmpty(props.idPhotoFront)}
        </label>
        <div className="flex w-full">
          <div className="relative py-2 text-left overflow-hidden transform transition-all sm:max-w-lg w-full">
            <div className="sm:flex sm:items-start">
              <div className="text-center mt-0 sm:text-left">
                <div className="mt-2 rounded-md">
                  {props.idPhotoFront ? (
                    <div className="flex flex-col items-center justify-center">
                      <img
                        className="rounded-md"
                        style={{
                          maxHeight: "40vh",
                          width: "auto",
                          margin: "0 auto",
                        }}
                        src={props.idPhotoFront}
                        alt="ID Front"
                      />
                    </div>
                  ) : (
                    <div className="relative">
                      {isUsingMobileDevice() ? (
                        <Webcam
                          audio={false}
                          ref={webcamRef}
                          screenshotFormat="image/jpeg"
                          height={640}
                          onUserMedia={handleUserMedia}
                          width={480}
                          videoConstraints={{
                            facingMode,
                            height: 640,
                            width: 480,
                          }}
                          style={{
                            margin: "0 auto",
                          }}
                        />
                      ) : (
                        <Webcam
                          audio={false}
                          ref={webcamRef}
                          screenshotFormat="image/jpeg"
                          width={640}
                          height={640}
                          onUserMedia={handleUserMedia}
                          videoConstraints={{ facingMode }}
                          style={{
                            maxHeight: "40vh",
                            width: "auto",
                            margin: "0 auto",
                          }}
                        />
                      )}
                      {cameraLoaded && (
                        <div className="z-30 absolute top-0 left-0 right-0 w-auto">
                          {idOverlay()}
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    Make sure your document is inside the frame
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-5 sm:mt-4 sm:flex">
              {props.idPhotoFront ? (
                <button
                  type="button"
                  className="items-center w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-emerald-500 text-sm sm:text-base font-medium text-white hover:bg-emerald-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 sm:mr-3 sm:w-auto disabled:opacity-80"
                  onClick={() => props.setIdPhotoFront("")}
                >
                  <CameraIcon className="h-5 w-5" />
                  <span className="ml-1.5">Retake</span>
                </button>
              ) : (
                <div className="flex flex-row">
                  <button
                    type="button"
                    className="items-center w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-emerald-500 text-sm sm:text-base font-medium text-white hover:bg-emerald-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 sm:mr-3 sm:w-auto disabled:opacity-80 mr-1.5"
                    onClick={capturePhoto}
                  >
                    <CameraIcon className="h-5 w-5" />
                    <span className="ml-1.5">Capture</span>
                  </button>
                  <button
                    type="button"
                    className="items-center w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-stone-500 hover:bg-stone-600 text-sm sm:text-base font-medium text-white hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-stone-500 sm:mt-0 sm:mr-3 sm:w-auto disabled:opacity-80"
                    onClick={() =>
                      setFacingMode(
                        facingMode === "user" ? "environment" : "user"
                      )
                    }
                  >
                    <RefreshIcon className="h-5 w-5" />
                    <span className="ml-1.5">Flip</span>
                  </button>
                </div>
              )}
            </div>
            {error && <div className="mt-4 text-red-600 text-sm">{error}</div>}
            {webcamError && (
              <div className="mt-4 text-red-600 text-sm">{webcamError}</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
