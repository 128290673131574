import axios, { AxiosInstance, AxiosRequestHeaders } from "axios";

class Api {
  token: string | null;
  client: AxiosInstance;
  apiBaseUrl: string;

  constructor() {
    this.apiBaseUrl = process.env.REACT_APP_API_BASE_URL ?? "";
    this.token = localStorage.getItem("token");

    let headers: AxiosRequestHeaders = {
      "Content-Type": "application/json",
    };

    if (this.token) {
      headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.token}`,
      };
    }

    this.client = axios.create({
      baseURL: this.apiBaseUrl,
      timeout: 31000,
      headers: headers,
    });

    this.client.interceptors.response.use(
      (response) => response,
      (error) => {
        if ([401, 403].includes(error.response.status)) {
          if (!window.location.href.includes("/signin")) {
            window.location.href = "/signout";
          }
        }
        return Promise.reject(error);
      }
    );
  }

  updateClient = (newToken: string) => {
    this.token = newToken;

    // Update API
    let headers: AxiosRequestHeaders = {
      "Content-Type": "application/json",
    };

    if (this.token) {
      headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.token}`,
      };
    }

    this.client = axios.create({
      baseURL: this.apiBaseUrl,
      timeout: 31000,
      headers: headers,
    });

    this.client.interceptors.response.use(
      (response) => response,
      (error) => {
        if ([401, 403].includes(error.response.status)) {
          if (!window.location.href.includes("/signin")) {
            window.location.href = "/signout";
          }
        }
        return Promise.reject(error);
      }
    );

    return this.client;
  };

  login = (email: string, password: string) =>
    this.client.post(`/visitors/login`, { email, password });

  register = (data: any) => this.client.post(`/visitors`, data);

  sendResetPasswordEmail = (email: string) =>
    this.client.post(`/visitors/email/reset-password`, { email });

  resetPassword = (newPassword: string) =>
    this.client.post(`/visitors/password/reset`, {
      newPassword,
    });

  changePassword = (
    visitorId: string,
    currentPassword: string,
    newPassword: string
  ) =>
    this.client.post(`/visitors/password/change`, {
      id: visitorId,
      currentPassword,
      newPassword,
    });

  createVisitorPass = (data: any) => this.client.post(`/visitor-passes`, data);

  listMyRequests = (page: number, size: number) =>
    this.client.get(`/visitor-passes/me?size=${size}&page=${page}`);

  updateVisitorPass = (id: any, data: any) =>
    this.client.patch(`/visitor-passes/${id}`, data);

  getVisitorInfo = () => this.client.get("/visitors/me");

  getVisitorPassById = (id: any) => this.client.get(`/visitor-passes/${id}`);

  getVisitorPassFileByPath = (visitorPassId: string, filePath: string) => {
    return this.client.get(`/visitor-passes/storage/${visitorPassId}`, {
      params: {
        filePath,
      },
    });
  };

  updateProfile = (id: any, data: any) =>
    this.client.patch(`/visitors/${id}`, data);

  validateVisitorAddress = (address: string) =>
    this.client.post(`/visitors/address/validate`, { address });

  verifyEmailAddress = () => this.client.post(`/visitors/email/verify`);

  verifyChangedEmailAddress = (newEmail: string) =>
    this.client.post(`/visitors/email/verify-changed-email`, {
      email: newEmail,
    });

  resendVerifyEmail = ({
    email,
    hashedPassword,
  }: {
    email: string;
    hashedPassword: string;
  }) => this.client.post("/visitors/email/resend", { email, hashedPassword });

  deleteVisitorPassById = (id: any) =>
    this.client.delete(`/visitor-passes/${id}`);

  getAirports = () =>
    this.client.get(`/airports`, {
      params: {
        enabled: true,
      },
    });

  approveVisitorPassSponsorById = (
    id: string,
    sponsorEmail: string,
    sponsorMobileNumber: string
  ) =>
    this.client.post(`/visitor-passes/sponsor/approve/${id}`, {
      sponsorEmail,
      sponsorMobileNumber,
    });

  declineVisitorPassAsSponsorById = (
    id: string,
    sponsorEmail: string,
    sponsorMobileNumber: string
  ) =>
    this.client.post(`/visitor-passes/sponsor/decline/${id}`, {
      sponsorEmail,
      sponsorMobileNumber,
    });

  updateVisitorPassAsSponsorById = (id: string, data: any) =>
    this.client.post(`/visitor-passes/sponsor/update/${id}`, data);

  resendSponsorVerificationRequest = (visitorPassId: string) =>
    this.client.post(`/visitor-passes/sponsor/resend/${visitorPassId}`);

  getSponsorDetail = (asicId: string) => this.client.get(`/sponsor/${asicId}`);
}

export default new Api();
