export const photoOverlay = () => (
  <svg
    width="100%"
    height="100%"
    className="svg"
    viewBox="0 0 260 200"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <mask id="overlay-mask" x="0" y="0" width="100%" height="100%">
        <rect x="0" y="0" width="100%" height="100%" fill="#fff" />
        <ellipse cx="50%" cy="50%" rx="70" ry="90" />
      </mask>
    </defs>
    <rect
      x="0"
      y="0"
      width="100%"
      height="100%"
      mask="url(#overlay-mask)"
      fillOpacity="0.7"
    />
  </svg>
);

export const idOverlay = () => (
  <svg
    width="100%"
    height="100%"
    className="svg"
    viewBox="0 0 260 200"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <mask id="overlay-mask" x="0" y="0" width="100%" height="100%">
        <rect x="0" y="0" width="100%" height="100%" fill="#fff" />
        <rect x="35" y="40" width="190" height="120" rx="5%" />
      </mask>
    </defs>
    <rect
      x="0"
      y="0"
      width="100%"
      height="100%"
      mask="url(#overlay-mask)"
      fillOpacity="0.7"
    />
  </svg>
);
