import {
  CogIcon,
  HomeIcon,
  PencilAltIcon,
  PlusIcon,
  RefreshIcon,
} from "@heroicons/react/outline";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { NavBar } from "../../components/NavBar/NavBar";
import { useAuth } from "../../utils/auth/auth";
import api from "../../utils/helpers/api";
import { classNames } from "../../utils/helpers/misc";
import useInfiniteScroll from "../../utils/helpers/useInfiniteScroll";

export const Dashboard = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const [vpApprovedCount, setVpApprovedCount] = useState(0);
  // const [vpExpiryDate, setVpExpiryDate] = useState<string>("");
  const [status, setStatus] = useState<number>(0);
  const [visitorPasses, setVisitorPasses] = useState<any[]>([]);
  const [filteredVisitorPasses, setFilteredVisitorPasses] = useState<any[]>([]);
  const [page, setPage] = useState(0);
  const size = 10;
  const [count, setCount] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [loading, setLoading] = useState(true);
  // const [visitorFlagged, setVisitorFlagged] = useState(false);
  const [visitorBlocked, setVisitorBlocked] = useState(false);

  const goToDashboard = () => {
    navigate("/dashboard");
  };

  const goToProfile = () => {
    navigate("/settings");
  };

  const navigation = [
    {
      name: "Home",
      onClick: goToDashboard,
      icon: HomeIcon,
      current: true,
    },
    { name: "Profile", onClick: goToProfile, icon: CogIcon, current: false },
  ];
  const tabs = [
    {
      name: "All",
      status: "All",
      current: status === 0,
      value: 0,
      color: "bg-amber-500",
    },
    {
      name: "Requested",
      status: "Requested",
      current: status === 1,
      value: 1,
      color: "bg-yellow-500",
    },
    {
      name: "Approved",
      status: "Approved",
      current: status === 2,
      value: 2,
      color: "bg-green-500",
    },
    {
      name: "Rejected",
      status: "Rejected",
      current: status === 3,
      value: 3,
      color: "bg-red-500",
    },
    {
      name: "Expired",
      status: "Expired",
      current: status === 4,
      value: 4,
      color: "bg-gray-500",
    },
    {
      name: "Cancelled",
      status: "Cancelled",
      current: status === 5,
      value: 5,
      color: "bg-red-800",
    },
    {
      name: "Printed",
      status: "Printed",
      current: status === 6,
      value: 6,
      color: "bg-blue-800",
    },
  ];

  useEffect(() => {
    if (auth.user && !auth.loading && !isFetching) {
      api.updateClient(auth.token);
      setPage(0);
      setVisitorPasses([]);
      setFilteredVisitorPasses([]);
      getCurrentVisitor();
      loadMoreItems();
    }
  }, [auth.loading, auth.user]);

  useEffect(() => {
    if (status === 0) {
      setFilteredVisitorPasses(visitorPasses);
    } else {
      setFilteredVisitorPasses(
        visitorPasses.filter((pass) => {
          return pass.status === status;
        })
      );
    }
  }, [status]);

  const getApprovedVPCount = (requests: any[]) => {
    const approvedPasses = requests.filter(
      (pass) => pass.status === 2 || pass.status === 4
    );
    setVpApprovedCount(approvedPasses.length);
  };

  const loadMoreItems = async (isRefresh = false) => {
    if (auth.user) {
      setIsFetching(true);
      setLoading(true);
      if (isRefresh) {
        setPage(0);
        setVisitorPasses([]);
      }
      try {
        const requests = await api.listMyRequests(page + 1, size);
        const allRequests = isRefresh
          ? [...requests.data.data]
          : [...visitorPasses, ...requests.data.data];

        getApprovedVPCount(allRequests);
        setVisitorPasses(allRequests);
        if (status === 0) {
          setFilteredVisitorPasses(allRequests);
        } else {
          setFilteredVisitorPasses(
            allRequests.filter((pass) => {
              return pass.status === status;
            })
          );
        }
        setCount(requests.data.totalCount);
        if (count >= visitorPasses.length) setHasMore(false);
        else setHasMore(true);
      } catch (error: any) {
        console.error("Error fetching user's visitor pass requests - ", error);
        if (error.response?.status === 401 || error.response?.status === 403) {
          navigate("/signout");
        }
      } finally {
        setLoading(false);
        setIsFetching(false);
      }
    }
  };

  // FIXME:
  const getCurrentVisitor = async () => {
    const visitorInfo = await api.getVisitorInfo();
    if (visitorInfo.data.status === 3) {
      setVisitorBlocked(true);
    }
    // FIXME:
    console.log("visitor info", visitorInfo.data);
    // setVpApprovedCount(visitorInfo.data.vpApprovedCount);
    // setVpExpiryDate(visitorInfo.data.vpExpiryDate);
  };

  const [lastElementRef] = useInfiniteScroll(
    hasMore ? loadMoreItems : null,
    isFetching
  );

  const refreshVisitorPasses = async () => {
    await loadMoreItems(true);
  };

  return (
    <div className="min-h-full">
      <NavBar />
      <div className="py-4 sm:py-10 fadeIn">
        <div className="max-w-3xl mx-auto sm:px-6 lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-12 lg:gap-8">
          <div className="hidden lg:block lg:col-span-3 xl:col-span-2">
            <nav
              aria-label="Sidebar"
              className="sticky top-4 divide-y divide-gray-300"
            >
              <div className="pb-8 space-y-1">
                {navigation.map((item) => (
                  <a
                    key={item.name}
                    onClick={item.onClick}
                    className={classNames(
                      item.current
                        ? "bg-gray-200 text-gray-900"
                        : "text-gray-600 hover:bg-gray-50",
                      "cursor-pointer group flex items-center px-3 py-2 font-medium rounded-md"
                    )}
                    aria-current={item.current ? "page" : undefined}
                  >
                    <item.icon
                      className={classNames(
                        item.current
                          ? "text-gray-500"
                          : "text-gray-400 group-hover:text-gray-500",
                        "flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                      )}
                      aria-hidden="true"
                    />
                    <span className="truncate">{item.name}</span>
                  </a>
                ))}
              </div>
            </nav>
          </div>
          <main className="lg:col-span-12 xl:col-span-10">
            <div className="py-4 sm:py-10 fadeIn">
              <div className="text-center">
                <h1 className="text-2xl font-semibold">Visitor Passes</h1>
              </div>

              <div className="text-center mt-2.5 sm:mt-10">
                <div className="inline-flex justify-center">
                  <button
                    type="button"
                    onClick={() => navigate("/visitor-pass/create")}
                    disabled={vpApprovedCount > 28 || visitorBlocked}
                    className="inline-flex items-center px-4 py-2 border border-transparent font-medium rounded-md shadow-sm text-white bg-amber-500 hover:bg-amber-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500 disabled:opacity-50 mr-4"
                  >
                    <PlusIcon className="h-4 w-4 mr-1.5" />
                    Request Pass
                  </button>
                  <button
                    type="button"
                    onClick={refreshVisitorPasses}
                    className="inline-flex items-center p-2 border border-transparent font-medium rounded-md shadow-sm text-white bg-gray-500 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                  >
                    <RefreshIcon className="h-5 w-5" />
                  </button>
                </div>
              </div>
            </div>
            {/* {visitorFlagged && (
              <div className="px-4 sm:px-0">
                <div
                  className={classNames(
                    "my-2 relative rounded-md bg-orange-600"
                  )}
                >
                  <div className="mx-auto max-w-7xl py-3 px-3 sm:px-6 lg:px-8">
                    <div className="pr-16 sm:px-16 sm:text-center">
                      <p className="font-medium text-white">
                        <span className="inline">
                          Your account has been flagged for review. You may not
                          request any new visitor passes until your account has
                          been reviewed successfully.
                        </span>
                      </p>
                    </div>
                    <div className="absolute inset-y-0 right-0 flex items-start pt-1 pr-1 sm:items-start sm:pt-1 sm:pr-2"></div>
                  </div>
                </div>
              </div>
            )} */}
            {visitorBlocked && (
              <div className="px-4 sm:px-0">
                <div
                  className={classNames("my-2 relative rounded-md bg-red-600")}
                >
                  <div className="mx-auto max-w-7xl py-3 px-3 sm:px-6 lg:px-8">
                    <div className="pr-16 sm:px-16 sm:text-center">
                      <p className="font-medium text-white">
                        <span className="inline">
                          Your account has been blocked and cannot request any
                          new visitor passes. Please contact security services
                          at Building 219.
                        </span>
                      </p>
                    </div>
                    <div className="absolute inset-y-0 right-0 flex items-start pt-1 pr-1 sm:items-start sm:pt-1 sm:pr-2"></div>
                  </div>
                </div>
              </div>
            )}

            {!visitorBlocked && (
              <div className="px-4 sm:px-0">
                <div
                  className={classNames(
                    "my-2 relative rounded-md",
                    vpApprovedCount >= 28 ? "bg-red-600" : "bg-stone-600"
                  )}
                >
                  <div className="mx-auto max-w-7xl py-3 px-3 sm:px-6 lg:px-8">
                    <div className="pr-16 sm:px-16 sm:text-center">
                      <p className="font-medium text-white">
                        <span className="inline">
                          {
                            // FIXME:
                            /* {vpApprovedCount < 28
                            ? vpExpiryDate
                              ? `You have had ${vpApprovedCount} of 28 passes approved in a 365-day period (until ${new Date(
                                  vpExpiryDate
                                ).toDateString()})`
                              : "You can create up to 28 visitor passes in a 365-day period"
                            : `You have had 28 of 28 visitor passes approved in a 365-day period. Please wait until ${new Date(
                                vpExpiryDate
                              ).toDateString()} to create more`} */
                          }
                          {/* {vpApprovedCount === 0
                            ? `You have had ${vpApprovedCount} of 28 passes approved in a 365-day period`
                            : "You can create up to 28 visitor passes in a 365-day period"} */}
                          {vpApprovedCount === 0
                            ? "You can create up to 28 visitor passes in a 365-day period"
                            : vpApprovedCount > 28
                            ? `You have had 28 of 28 visitor passes approved in a 365-day period`
                            : `You have had ${vpApprovedCount} of 28 passes approved in a 365-day period`}
                        </span>
                      </p>
                    </div>
                    <div className="absolute inset-y-0 right-0 flex items-start pt-1 pr-1 sm:items-start sm:pt-1 sm:pr-2"></div>
                  </div>
                </div>
              </div>
            )}

            <div className="px-4 sm:px-0">
              <div className="sm:hidden">
                <label htmlFor="question-tabs" className="sr-only">
                  Select a tab
                </label>
                <select
                  id="question-tabs"
                  className="block w-full rounded-md border-gray-300 text-base font-medium text-gray-900 shadow-sm focus:border-rose-500 focus:ring-rose-500"
                  defaultValue={tabs.find((tab) => tab.current)?.name}
                  onChange={(e) => setStatus(parseInt(e.target.value, 10))}
                >
                  {tabs.map((tab) => (
                    <option key={tab.name} value={tab.value}>
                      {tab.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="hidden sm:block">
                <nav
                  className="relative z-0 rounded-lg shadow flex divide-x divide-gray-200"
                  aria-label="Tabs"
                >
                  {tabs.map((tab, tabIdx) => (
                    <a
                      key={tab.name}
                      onClick={() => setStatus(tab.value)}
                      aria-current={tab.current ? "page" : undefined}
                      className={classNames(
                        tab.current
                          ? "text-gray-900"
                          : "text-gray-500 hover:text-gray-700",
                        tabIdx === 0 ? "rounded-l-lg" : "",
                        tabIdx === tabs.length - 1 ? "rounded-r-lg" : "",
                        "group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-6 font-medium text-center hover:bg-gray-100 focus:z-10 cursor-pointer"
                      )}
                    >
                      <span>{tab.name}</span>
                      <span
                        aria-hidden="true"
                        className={classNames(
                          tab.current ? tab.color : "bg-transparent",
                          "absolute inset-x-0 bottom-0 h-0.5"
                        )}
                      />
                    </a>
                  ))}
                </nav>
              </div>
            </div>
            <div className="mt-4 sm:px-0 px-4">
              {loading ? (
                <div className="px-1 animate-pulse fadeIn">
                  <ul
                    role="list"
                    className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 block sm:hidden"
                  >
                    <li className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200">
                      <div className="w-full flex items-center justify-between p-6 space-x-6">
                        <div className="flex-1 truncate">
                          <div className="flex items-center space-x-3">
                            <h3 className="bg-gray-100 text-gray-100 text-sm font-medium truncate">
                              Unknown Duration
                            </h3>
                            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-100">
                              Approved
                            </span>
                          </div>
                          <p className="mt-1 bg-gray-100 text-gray-100 text-sm truncate">
                            Starting on {new Date().toLocaleString("en-AU")}
                          </p>
                          <p className="mt-1 bg-gray-100 text-gray-100 text-sm truncate">
                            At Sample Airport
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="-mt-px flex divide-x divide-gray-200 bg-gray-100 text-gray-100">
                          <div className="w-0 flex-1 flex">
                            <button className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm font-medium border border-transparent rounded-bl-lg bg-gray-100 text-gray-100">
                              <PencilAltIcon
                                className="w-5 h-5 text-gray-400"
                                aria-hidden="true"
                              />
                              <span className="ml-3">Manage</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200">
                      <div className="w-full flex items-center justify-between p-6 space-x-6">
                        <div className="flex-1 truncate">
                          <div className="flex items-center space-x-3">
                            <h3 className="bg-gray-100 text-gray-100 text-sm font-medium truncate">
                              Unknown Duration
                            </h3>
                            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-100">
                              Approved
                            </span>
                          </div>
                          <p className="mt-1 bg-gray-100 text-gray-100 text-sm truncate">
                            Starting on {new Date().toLocaleString("en-AU")}
                          </p>
                          <p className="mt-1 bg-gray-100 text-gray-100 text-sm truncate">
                            At Sample Airport
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="-mt-px flex divide-x divide-gray-200 bg-gray-100 text-gray-100">
                          <div className="w-0 flex-1 flex">
                            <button className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm font-medium border border-transparent rounded-bl-lg bg-gray-100 text-gray-100">
                              <PencilAltIcon
                                className="w-5 h-5 text-gray-400"
                                aria-hidden="true"
                              />
                              <span className="ml-3">Manage</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200">
                      <div className="w-full flex items-center justify-between p-6 space-x-6">
                        <div className="flex-1 truncate">
                          <div className="flex items-center space-x-3">
                            <h3 className="bg-gray-100 text-gray-100 text-sm font-medium truncate">
                              Unknown Duration
                            </h3>
                            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-100">
                              Approved
                            </span>
                          </div>
                          <p className="mt-1 bg-gray-100 text-gray-100 text-sm truncate">
                            Starting on {new Date().toLocaleString("en-AU")}
                          </p>
                          <p className="mt-1 bg-gray-100 text-gray-100 text-sm truncate">
                            At Sample Airport
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="-mt-px flex divide-x divide-gray-200 bg-gray-100 text-gray-100">
                          <div className="w-0 flex-1 flex">
                            <button className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm font-medium border border-transparent rounded-bl-lg bg-gray-100 text-gray-100">
                              <PencilAltIcon
                                className="w-5 h-5 text-gray-400"
                                aria-hidden="true"
                              />
                              <span className="ml-3">Manage</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <div className="overflow-x-auto overflow-y-hidden shadow ring-1 ring-black ring-opacity-5 rounded-md hidden sm:block">
                    <table className="min-w-full divide-y divide-gray-300 text-xs sm:text-sm">
                      <thead className="bg-gray-100">
                        <tr>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left font-semibold text-gray-900"
                          >
                            Requested Date
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left font-semibold text-gray-900"
                          >
                            Start Time
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left font-semibold text-gray-900"
                          >
                            Duration
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left font-semibold text-gray-900"
                          >
                            End Time
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left font-semibold text-gray-900"
                          >
                            Airport
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left font-semibold text-gray-900"
                          >
                            Sponsor Status
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left font-semibold text-gray-900"
                          >
                            Status
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        <tr className="hover:bg-gray-50 cursor-pointer">
                          <td className="whitespace-nowrap px-3 py-4 rounded-full">
                            <span className="h-2 bg-gray-200 rounded text-gray-200">
                              12123123123
                            </span>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 rounded-full">
                            <span className="h-2 bg-gray-200 rounded text-gray-200">
                              {new Date().toLocaleString("en-AU")}
                            </span>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 rounded-full">
                            <span className="h-2 bg-gray-200 rounded text-gray-200">
                              24 Hours
                            </span>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 rounded-full">
                            <span className="h-2 bg-gray-200 rounded text-gray-200">
                              {new Date().toLocaleString("en-AU")}
                            </span>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 rounded-full">
                            <span className="h-2 bg-gray-200 rounded text-gray-200">
                              Sample Airport
                            </span>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 rounded-full">
                            <span className="h-2 bg-gray-200 rounded text-gray-200">
                              Requested
                            </span>
                          </td>
                        </tr>
                        <tr className="hover:bg-gray-50 cursor-pointer">
                          <td className="whitespace-nowrap px-3 py-4 rounded-full">
                            <span className="h-2 bg-gray-200 rounded text-gray-200">
                              12
                            </span>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 rounded-full">
                            <span className="h-2 bg-gray-200 rounded text-gray-200">
                              {new Date().toLocaleString("en-AU")}
                            </span>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 rounded-full">
                            <span className="h-2 bg-gray-200 rounded text-gray-200">
                              24 Hours
                            </span>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 rounded-full">
                            <span className="h-2 bg-gray-200 rounded text-gray-200">
                              {new Date().toLocaleString("en-AU")}
                            </span>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 rounded-full">
                            <span className="h-2 bg-gray-200 rounded text-gray-200">
                              Sample Airport
                            </span>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 rounded-full">
                            <span className="h-2 bg-gray-200 rounded text-gray-200">
                              Requested
                            </span>
                          </td>
                        </tr>
                        <tr className="hover:bg-gray-50 cursor-pointer">
                          <td className="whitespace-nowrap px-3 py-4 rounded-full">
                            <span className="h-2 bg-gray-200 rounded text-gray-200">
                              12
                            </span>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 rounded-full">
                            <span className="h-2 bg-gray-200 rounded text-gray-200">
                              {new Date().toLocaleString("en-AU")}
                            </span>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 rounded-full">
                            <span className="h-2 bg-gray-200 rounded text-gray-200">
                              24 Hours
                            </span>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 rounded-full">
                            <span className="h-2 bg-gray-200 rounded text-gray-200">
                              {new Date().toLocaleString("en-AU")}
                            </span>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 rounded-full">
                            <span className="h-2 bg-gray-200 rounded text-gray-200">
                              Sample Airport
                            </span>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 rounded-full">
                            <span className="h-2 bg-gray-200 rounded text-gray-200">
                              Requested
                            </span>
                          </td>
                        </tr>
                        <tr className="hover:bg-gray-50 cursor-pointer">
                          <td className="whitespace-nowrap px-3 py-4 rounded-full">
                            <span className="h-2 bg-gray-200 rounded text-gray-200">
                              12
                            </span>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 rounded-full">
                            <span className="h-2 bg-gray-200 rounded text-gray-200">
                              {new Date().toLocaleString("en-AU")}
                            </span>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 rounded-full">
                            <span className="h-2 bg-gray-200 rounded text-gray-200">
                              24 Hours
                            </span>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 rounded-full">
                            <span className="h-2 bg-gray-200 rounded text-gray-200">
                              {new Date().toLocaleString("en-AU")}
                            </span>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 rounded-full">
                            <span className="h-2 bg-gray-200 rounded text-gray-200">
                              Sample Airport
                            </span>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 rounded-full">
                            <span className="h-2 bg-gray-200 rounded text-gray-200">
                              Requested
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : filteredVisitorPasses.length > 0 ? (
                <div className="px-1 fadeIn">
                  <ul
                    role="list"
                    className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 block sm:hidden"
                  >
                    {filteredVisitorPasses.map((pass) => (
                      <li
                        key={pass.id}
                        className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200"
                      >
                        <div className="w-full flex items-center justify-between p-6 space-x-6">
                          <div className="flex-1 truncate">
                            <div className="flex items-center space-x-3">
                              <h3 className="text-gray-900 text-sm font-semibold truncate">
                                {pass.rangeType ?? "Unknown Duration"}
                              </h3>
                            </div>
                            <div className="flex items-center mt-0.5 space-x-1">
                              {pass.status === 1 && (
                                <span className="inline-flex items-center px-2.5 py-0.5 rounded-md text-xs font-medium bg-yellow-100 text-yellow-800">
                                  Requested
                                </span>
                              )}
                              {pass.status === 2 && (
                                <span className="inline-flex items-center px-2.5 py-0.5 rounded-md text-xs font-medium bg-green-100 text-green-800">
                                  Approved
                                </span>
                              )}
                              {pass.status === 3 && (
                                <span className="inline-flex items-center px-2.5 py-0.5 rounded-md text-xs font-medium bg-gray-100 text-gray-800">
                                  Rejected
                                </span>
                              )}
                              {pass.status === 4 && (
                                <span className="inline-flex items-center px-2.5 py-0.5 rounded-md text-xs font-medium bg-gray-100 text-gray-800">
                                  Expired
                                </span>
                              )}
                              {pass.status === 5 && (
                                <span className="inline-flex items-center px-2.5 py-0.5 rounded-md text-xs font-medium bg-red-100 text-red-800">
                                  Cancelled
                                </span>
                              )}
                              {pass.status === 6 && (
                                <span className="inline-flex items-center px-2.5 py-0.5 rounded-md text-xs font-medium bg-blue-100 text-blue-800">
                                  Printed
                                </span>
                              )}
                              {pass.sponsorStatus === 0 && (
                                <span className="inline-flex items-center px-2.5 py-0.5 rounded-md text-xs font-medium bg-gray-100 text-gray-800">
                                  Unknown
                                </span>
                              )}
                              {pass.sponsorStatus === 1 && (
                                <span className="inline-flex items-center px-2.5 py-0.5 rounded-md text-xs font-medium bg-yellow-100 text-yellow-800">
                                  Sponsor Pending
                                </span>
                              )}
                              {pass.sponsorStatus === 2 && (
                                <span className="inline-flex items-center px-2.5 py-0.5 rounded-md text-xs font-medium bg-green-100 text-green-800">
                                  Sponsor Approved
                                </span>
                              )}
                              {pass.sponsorStatus === 3 && (
                                <span className="inline-flex items-center px-2.5 py-0.5 rounded-md text-xs font-medium bg-red-100 text-red-800">
                                  Sponsor Declined
                                </span>
                              )}
                            </div>
                            <p className="mt-1 text-gray-500 text-sm truncate">
                              Starting on{" "}
                              {pass.startTime
                                ? new Date(pass.startTime).toLocaleString(
                                    "en-AU"
                                  )
                                : ""}
                            </p>
                            {pass.airport && (
                              <p className="mt-1 font-semibold text-gray-500 text-sm truncate">
                                At {pass.airport.name}
                              </p>
                            )}
                          </div>
                        </div>
                        <div>
                          <div className="-mt-px flex divide-x divide-gray-200">
                            <div className="w-0 flex-1 flex">
                              <button
                                onClick={() =>
                                  navigate("/visitor-pass/details/" + pass.id)
                                }
                                className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500"
                              >
                                <PencilAltIcon
                                  className="w-5 h-5 text-gray-400"
                                  aria-hidden="true"
                                />
                                <span className="ml-3">Manage</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                  <div className="overflow-x-auto overflow-y-hidden shadow ring-1 ring-black ring-opacity-5 rounded-md hidden sm:block">
                    <table className="min-w-full divide-y divide-gray-300 text-xs sm:text-sm ">
                      <thead className="bg-gray-100">
                        <tr>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left font-semibold text-gray-900"
                          >
                            Requested Date
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left font-semibold text-gray-900"
                          >
                            Start Time
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left font-semibold text-gray-900"
                          >
                            Duration
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left font-semibold text-gray-900"
                          >
                            End Time
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left font-semibold text-gray-900"
                          >
                            Airport
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left font-semibold text-gray-900"
                          >
                            Sponsor Status
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left font-semibold text-gray-900"
                          >
                            Status
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {filteredVisitorPasses.map((pass) => (
                          <tr
                            key={pass.pass_id}
                            className="hover:bg-gray-50 cursor-pointer"
                            onClick={() =>
                              navigate("/visitor-pass/details/" + pass.id)
                            }
                          >
                            <td className="font-semibold whitespace-nowrap px-3 py-4 text-gray-500">
                              {pass.createdAt
                                ? new Date(pass.createdAt).toLocaleDateString(
                                    "en-AU"
                                  )
                                : ""}
                            </td>

                            <td className="whitespace-nowrap px-3 py-4 text-gray-500">
                              {pass.startTime
                                ? new Date(pass.startTime).toLocaleString(
                                    "en-AU"
                                  )
                                : ""}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-gray-500">
                              {pass.rangeType ?? ""}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-gray-500">
                              {pass.endTime
                                ? new Date(pass.endTime).toLocaleString("en-AU")
                                : ""}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-gray-500">
                              {pass.airport
                                ? pass.airport.name
                                : "No Airport Specified"}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-gray-500">
                              {pass.sponsorStatus === 0 && (
                                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                                  Unknown
                                </span>
                              )}
                              {pass.sponsorStatus === 1 && (
                                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
                                  Pending
                                </span>
                              )}
                              {pass.sponsorStatus === 2 && (
                                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                                  Approved
                                </span>
                              )}
                              {pass.sponsorStatus === 3 && (
                                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                                  Declined
                                </span>
                              )}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-gray-500">
                              {pass.status === 0 && (
                                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                                  Unknown
                                </span>
                              )}
                              {pass.status === 1 && (
                                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
                                  Requested
                                </span>
                              )}
                              {pass.status === 2 && (
                                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                                  Approved
                                </span>
                              )}
                              {pass.status === 3 && (
                                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                                  Rejected
                                </span>
                              )}
                              {pass.status === 4 && (
                                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                                  Expired
                                </span>
                              )}
                              {pass.status === 5 && (
                                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-200 text-red-900">
                                  Cancelled
                                </span>
                              )}
                              {pass.status === 6 && (
                                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-200 text-blue-900">
                                  Printed
                                </span>
                              )}
                            </td>
                          </tr>
                        ))}
                        <tr ref={lastElementRef}></tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : (
                <div className="px-1 fadeIn">
                  <h2 className="text-lg font-semibold text-gray-900">
                    No visitor passes could be found
                  </h2>
                  <p className="mt-1 text-gray-500">
                    Get started by creating a visitor pass request above
                  </p>
                </div>
              )}
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};
